import { Button, CircularProgress, Menu, MenuItem, Tooltip } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { getXl } from "../../utilities/openingsApi";
import { useGridApi } from "../../app/contexts/GridApiProvider";
import { getFilteredRecords } from "../Filter/getFilteredRecords";

const DownloadCandidates = () => {
	const { gridApi } = useGridApi();

	const {
		openingDetails: { selectedOpening },
		candidates: { candidates },
	} = useSelector((state) => state);
	const { isGridFilterApplied, isFilterApplied, gridFilter } = useSelector((state) => state.filters);
	const [loadingXl, setLoadingXl] = React.useState(false);
	const [showMenu, setShowMenu] = React.useState({
		show: false,
		options: [],
	});
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEl(null);
		setShowMenu({
			show: false,
			options: [],
		});
	};

	const handleDownloadExcel = async (visible = false, ids = []) => {
		setLoadingXl(true);
		try {
			const response = await getXl({ openingId: selectedOpening?._id, visible, ids: ids });
			if (response.data) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `zinterview_${selectedOpening.title}.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (error) {
			console.error("Failed to download Excel file:", error);
			throw new Error(error.response?.data?.message || error.message);
		} finally {
			setLoadingXl(false);
		}
	};

	const downloadAll = () => {
		handleDownloadExcel();
	};

	const downloadVisible = () => {
		handleDownloadExcel(true);
	};

	const downloadFiltered = (ids) => {
		handleDownloadExcel(false, ids);
	};

	const onClickDownload = (e) => {
		let buttons = [];
		try {
			if (isGridFilterApplied) {
				let rowIds = gridApi?.getAllRowIds();
				let ids = getFilteredRecords(gridFilter, candidates, rowIds, selectedOpening);
				ids = ids.map((el) => el._id);
				let count = ids.length;
				buttons.push({
					label: "Download Filtered Candidates " + (count ? `(${count})` : ""),
					toolip: "Download a list of only the currently filtered candidates",
					onClick: () => downloadFiltered(ids),
				});
			} else if (isFilterApplied && !isGridFilterApplied) {
				let ids = gridApi?.getAllRowIds();
				let count = ids.length;
				buttons.push({
					label: "Download Filtered Candidates " + (count ? `(${count})` : ""),
					toolip: "Download a list of only the currently filtered candidates",
					onClick: () => downloadFiltered(ids),
				});
			}
		} catch (error) {
			console.error(error);
		}
		let hiddenCandidates = candidates.find((el) => el.hasOwnProperty("hidden") && el.hidden);
		if (hiddenCandidates) {
			buttons.push({
				label: "Download Visible Candidates",
				toolip: "Download a list of only the currently visible candidates",
				onClick: downloadVisible,
			});
			buttons.push({
				label: "Download All Candidates",
				toolip: "Download the complete list of candidates, including those that are hidden",
				onClick: downloadAll,
			});
		}
		if (buttons.length === 0) {
			downloadAll();
		} else {
			setAnchorEl(e.currentTarget);
			setShowMenu({
				show: true,
				options: buttons,
			});
		}
	};

	return (
		<>
			<Button
				className="normal-case  focus:outline-none focus:border-none transition duration-300 ease-in-out text-white px-4 py-2 rounded-lg flex items-center justify-start gap-3"
				// component={Button}
				aria-controls={open ? "basic-download-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				disabled={loadingXl}
				onClick={onClickDownload}
				sx={{
					backgroundColor: "#10B981",
					":hover": {
						backgroundColor: "#047857",
					},
				}}
			>
				{!loadingXl ? (
					<>
						<i className="fas fa-file-excel ml-1"></i>
						<div>Download Candidates</div>
					</>
				) : (
					<div className="flex items-center relative justify-center">
						<div className="absolute scale-50 -left-4">
							<CircularProgress className="text-white" />
						</div>
						<div className="ml-5">Downloading...</div>
					</div>
				)}
			</Button>
			{showMenu.show && (
				<Menu
					id="basic-download-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
				>
					{showMenu.options.map((el, index) => {
						return (
							<Tooltip key={index} title={el.toolip} placement="right">
								<MenuItem
									// key={index}
									className="hover:scale-105 transition-all hover:bg-sky-50 text-sm"
									onClick={(e) => {
										el.onClick(e);
										handleClose();
									}}
								>
									{el.label}
								</MenuItem>
							</Tooltip>
						);
					})}
				</Menu>
			)}
		</>
	);
};

export default DownloadCandidates;
