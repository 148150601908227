import {
	ADD_FILTER,
	ADD_GRID_FILTER,
	ADD_ROUND_ID,
	REMOVE_ALL_FILTER,
	REMOVE_FILTER,
} from "../actions/filter/types";

const initialState = {
	criteria: [],
	isFilterApplied: false,
	gridFilter: [],
	isGridFilterApplied: false,
	selectedRoundId: null,
};

export default function filterReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case ADD_FILTER:
			return {
				...state,
				criteria: payload,
				isFilterApplied: payload.length > 0,
			};
		case REMOVE_FILTER:
			return {
				...state,
				criteria: [],
				isFilterApplied: false,
			};
		case ADD_GRID_FILTER:
			return {
				...state,
				gridFilter: payload,
				isGridFilterApplied: payload.length > 0,
			};
		case REMOVE_ALL_FILTER:
			return {
				...state,
				criteria: [],
				isFilterApplied: false,
				gridFilter: [],
				isGridFilterApplied: false,
				selectedRoundId: null,
			};
		case ADD_ROUND_ID:
			return { ...state, selectedRoundId: payload.roundId };
		default:
			return state;
	}
}
