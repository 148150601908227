import {
	SELECT_ALL_CANDIDATE,
	SELECT_CANDIDATE,
	UNSELECT_ALL_CANDIDATE,
	UNSELECT_CANDIDATE,
	HIDE_HIDDEN_CANDIDATES,
	SHOW_HIDDEN_CANDIDATES,
	SET_ALL_CANDIDATES,
	REMOVE_ALL_CANDIDATES,
	ADD_CANDIDATE,
	REMOVE_CANDIDATE,
	UPDATE_CANDIDATE,
	HIDE_CANDIDATES,
	UNHIDE_CANDIDATES,
	HIDE_SINGLE_CANDIDATE,
	UNHIDE_SINGLE_CANDIDATE,
	SHOW_COMPLETED_INTERVIEWS,
	SET_LOADING_FETCH_REPORTS,
	ADD_CANDIDATES,
	BULK_UPDATE_ROUND_ID,
	SHOW_ALL_CANDIDATES,
} from "../actions/candidates/types";

const initialState = {
	selectedCandidate: [],
	count: 0,
	showHiddenCandidates: false,
	candidates: [],
	loading: false,
	showCompletedInterviews: false,
	isFetchingCandidates: false,
	showAllCandidates: !false,
};

export default function candidatesReducer(state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case SELECT_CANDIDATE:
			return {
				...state,
				selectedCandidate: [...state.selectedCandidate, payload],
				count: state.count + 1,
				candidates: state.candidates.map((candidate) =>
					candidate._id === payload ? { ...candidate, selected: true } : candidate,
				),
				loading: false,
			};
		case UNSELECT_CANDIDATE:
			return {
				...state,
				selectedCandidate: state.selectedCandidate.filter((candidate) => candidate !== payload),
				count: state.count - 1,
				candidates: state.candidates.map((candidate) =>
					candidate._id === payload ? { ...candidate, selected: false } : candidate,
				),
				loading: false,
			};
		case SELECT_ALL_CANDIDATE:
			return {
				...state,
				selectedCandidate: payload,
				count: payload.length,
				loading: false,
			};
		case ADD_CANDIDATE:
			return {
				...state,
				candidates: [payload, ...state.candidates],
				loading: false,
			};
		case ADD_CANDIDATES:
			return {
				...state,
				candidates: [...payload, ...state.candidates],
				loading: false,
			};
		case REMOVE_CANDIDATE:
			return {
				...state,
				candidates: state.candidates.filter((candidate) => candidate._id !== payload),
				loading: false,
				selectedCandidate: state.selectedCandidate.filter((candidate) => candidate !== payload),
				count: state.count - 1,
			};
		case UPDATE_CANDIDATE:
			return {
				...state,
				candidates: state.candidates.map((candidate) =>
					candidate._id === payload._id ? payload : candidate,
				),
				loading: false,
			};
		case SET_ALL_CANDIDATES:
			return {
				...state,
				candidates: payload,
				count: 0,
				selectedCandidate: [],
				showHiddenCandidates: false,
				loading: false,
			};
		case UNSELECT_ALL_CANDIDATE:
			return {
				...state,
				selectedCandidate: [],
				candidates: state.candidates.map((candidate) => ({ ...candidate, selected: false })),
				count: 0,
				loading: false,
			};
		case REMOVE_ALL_CANDIDATES:
			return {
				...state,
				candidates: [],
				count: 0,
				selectedCandidate: [],
				loading: false,
			};
		case SHOW_HIDDEN_CANDIDATES:
			return {
				...state,
				showHiddenCandidates: true,
				candidates: state.candidates.map((candidate) => ({ ...candidate, selected: false })),
				selectedCandidate: [],
				count: 0,
				loading: false,
			};
		case HIDE_HIDDEN_CANDIDATES:
			return {
				...state,
				showHiddenCandidates: false,
				candidates: state.candidates.map((candidate) => ({ ...candidate, selected: false })),
				selectedCandidate: [],
				count: 0,
				loading: false,
			};
		case SHOW_COMPLETED_INTERVIEWS:
			return {
				...state,
				showCompletedInterviews: !state.showCompletedInterviews,
			};
		case HIDE_CANDIDATES:
			return {
				...state,
				candidates: state.candidates.map((candidate) =>
					state.selectedCandidate.includes(candidate._id)
						? { ...candidate, hidden: true }
						: candidate,
				),
				selectedCandidate: [],
				count: 0,
				loading: false,
			};
		case UNHIDE_CANDIDATES:
			return {
				...state,
				candidates: state.candidates.map((candidate) =>
					state.selectedCandidate.includes(candidate._id)
						? { ...candidate, hidden: false }
						: candidate,
				),
				selectedCandidate: [],
				count: 0,
				loading: false,
			};
		case HIDE_SINGLE_CANDIDATE:
			return {
				...state,
				candidates: state.candidates.map((candidate) =>
					candidate._id === payload ? { ...candidate, hidden: true } : candidate,
				),
				loading: false,
			};
		case UNHIDE_SINGLE_CANDIDATE:
			return {
				...state,
				candidates: state.candidates.map((candidate) =>
					candidate._id === payload ? { ...candidate, hidden: false } : candidate,
				),
				loading: false,
			};
		case "SET_LOADING":
			return {
				...state,
				loading: payload,
			};
		case SET_LOADING_FETCH_REPORTS:
			return {
				...state,
				isFetchingCandidates: payload,
			};
		case BULK_UPDATE_ROUND_ID:
			const set = new Set(payload.interviewReportIds);
			return {
				...state,
				candidates: state.candidates.map((candidate) => {
					if (set.has(candidate._id)) {
						return { ...candidate, roundId: payload.roundId };
					}
					return candidate;
				}),
			};
		case SHOW_ALL_CANDIDATES:
			return {
				...state,
				showAllCandidates: !state.showAllCandidates,
			};
		default:
			return state;
	}
}
