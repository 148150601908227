export const SELECT_CANDIDATE = "SELECT_CANDIDATE";
export const UNSELECT_CANDIDATE = "UNSELECT_CANDIDATE";
export const SELECT_ALL_CANDIDATE = "SELECT_ALL_CANDIDATE";
export const UNSELECT_ALL_CANDIDATE = "UNSELECT_ALL_CANDIDATE";
export const ADD_CANDIDATE = "ADD_CANDIDATE";
export const REMOVE_CANDIDATE = "REMOVE_CANDIDATE";
export const UPDATE_CANDIDATE = "UPDATE_CANDIDATE";
export const SET_ALL_CANDIDATES = "SET_ALL_CANDIDATES";
export const REMOVE_ALL_CANDIDATES = "REMOVE_ALL_CANDIDATES";
export const SHOW_HIDDEN_CANDIDATES = "SHOW_HIDDEN_CANDIDATES";
export const HIDE_HIDDEN_CANDIDATES = "HIDE_HIDDEN_CANDIDATES";
export const HIDE_CANDIDATES = "HIDE_CANDIDATES";
export const UNHIDE_CANDIDATES = "UNHIDE_CANDIDATES";
export const HIDE_SINGLE_CANDIDATE = "HIDE_SINGLE_CANDIDATE";
export const UNHIDE_SINGLE_CANDIDATE = "UNHIDE_SINGLE_CANDIDATE";
export const SHOW_COMPLETED_INTERVIEWS = "SHOW_COMPLETED_INTERVIEWS";
export const SET_LOADING_FETCH_REPORTS = "SET_LOADING_FETCH_REPORTS";
export const ADD_CANDIDATES = "ADD_CANDIDATES";
export const BULK_UPDATE_ROUND_ID = "BULK_UPDATE_ROUND_ID";
export const SHOW_ALL_CANDIDATES = "SHOW_ALL_CANDIDATES";
