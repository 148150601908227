import { MenuItem, TextField } from "@mui/material/node";

let interviewStatus = [
	{
		value: "scheduled",
		label: "Scheduled",
	},
	{
		value: "completed",
		label: "Completed",
	},
	{
		value: "cancelled",
		label: "Cancelled",
	},
	{
		value: "dropped",
		label: "Dropped",
	},
	{
		value: "ongoing",
		label: "Ongoing",
	},
	{
		value: "new",
		label: "New",
	},
];

let InputComponent = (props) => {
	return (
		<div>
			<TextField
				select
				value={props.item.value || ""}
				onChange={(event) => props.applyValue({ ...props.item, value: event.target.value })}
				style={{ width: "95%" }}
				size="small"
			>
				{props.options.map((option) => (
					<MenuItem key={option.value} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</TextField>
		</div>
	);
};

const dropDownFilter = (comparator, options = [], forInterviewStatus = false) => {
	if (forInterviewStatus) {
		options = interviewStatus;
	}

	let filter = [
		{
			label: "Is",
			value: "is",
			getApplyFilterFn: (filterItem) => {
				if (!filterItem.value) {
					return null;
				}
				return (value, row) => {
					return comparator(row, filterItem.value);
				};
			},
			InputComponent: (props) => <InputComponent {...props} options={options} />,
		},
	];

	return filter;
};

export default dropDownFilter;
