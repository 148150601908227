import { Box, Button, IconButton, Modal } from "@mui/material";
import React, { useState } from "react";
import { customFieldsModalStyles } from "../../utilities/constants";
import { ColStack, Label } from "../QuestionBank/Common";
import { ErrorMessage } from "../../pages/CreateUpdateOpening/CreateUpdateOpeningWithJD";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import {
	useAddNewRoundMutation,
	useGetRoundsForAnOpeningQuery,
	useUpdateRoundMutation,
} from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import LinkRoundToOpening from "./LinkRoundToOpening";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { default as CustomButton } from "../QuestionBank/Button";

const AddEditRound = () => {
	const [open, setOpen] = useState(false);

	const handleModalOpen = () => {
		setOpen(true);
	};

	const handleModalClose = () => {
		setOpen(false);
	};
	return (
		<>
			<Button
				variant="outlined"
				startIcon={<AddIcon className="scale-90" />}
				className={` flex-shrink-0  shadow-none normal-case focus:outline-none focus:border-none transition duration-300 ease-in-out px-4 py-2 rounded-md flex items-center justify-between`}
				onClick={handleModalOpen}
			>
				Add Round/Stage
			</Button>
			<Modal open={open} onClose={handleModalClose}>
				<Box sx={customFieldsModalStyles}>
					<h3 className="text-center my-2">
						Create or link rounds to openings to promote candidates to
					</h3>
					<div className="flex flex-col gap-4">
						<LinkRoundToOpening handleModalClose={handleModalClose} />
						<div className="h-[1px]  bg-slate-300 mx-2"></div>
						<CreateRound handleModalClose={handleModalClose} />
					</div>
				</Box>
			</Modal>
		</>
	);
};

export default AddEditRound;

const roundSchema = z.object({
	_id: z.string().optional(),
	name: z.string().trim().min(1).max(30),
	organizationId: z.string().optional(),
	isFinalRound: z.boolean().optional(),
});

const defaultValues = {
	name: "",
	isFinalRound: false,
};
export const CreateRound = ({ handleModalClose }) => {
	const { openingId } = useParams();
	const [addNewRound, { isLoading: isAddingNewRound }] = useAddNewRoundMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		defaultValues,
		resolver: zodResolver(roundSchema),
	});

	const onSubmit = async (data) => {
		try {
			const res = await addNewRound({
				...data,
				openingId,
			}).unwrap();
			handleModalClose();
		} catch (error) {
			const message = error?.data?.message || error.message;
			toast.warn(message);
		} finally {
		}
	};
	return (
		<>
			<form
				noValidate
				onSubmit={handleSubmit(onSubmit)}
				className="flex flex-col gap-4 p-5  create-update-opening"
			>
				<h4>Create a new round and it gets automatically linked to the current opening</h4>
				<ColStack>
					<Label htmlFor={"new-round"}>Round Name</Label>
					<input
						type="text"
						id="new-round"
						{...register("name")}
						className="mt-1 w-full rounded-md border p-2 disabled:opacity-50"
						placeholder="Enter Round name"
					/>
					<ErrorMessage errorCriteria={errors?.name?.message} />
				</ColStack>
				{/* <ColStack>
					<Label htmlFor={"is-final-round"} className="select-none flex gap-2">
						<input
							{...register("isFinalRound")}
							type="checkbox"
							className="align-middle scale-105 select-none"
							id="is-final-round"
						/>
						Is Final Round?
					</Label>
				</ColStack> */}
				<CustomButton type="submit" disabled={isAddingNewRound}>
					Create
				</CustomButton>
			</form>
		</>
	);
};

export const EditRoundsForm = ({ handleModalClose }) => {
	const { openingId } = useParams();
	const { data } = useGetRoundsForAnOpeningQuery(openingId);

	return (
		<>
			<div className="flex justify-between items-center mb-4">
				<h4>Update Round names</h4>
				<IconButton onClick={handleModalClose}>
					<CloseIcon className="hover:text-red-500" />
				</IconButton>
			</div>
			<div className="flex flex-col gap-2">
				{data?.rounds?.map((round) => {
					const values = {
						name: round.roundData.name,
						_id: round.roundId,
					};
					return (
						<UpdateRound
							key={round._id}
							values={values}
							isSystemGeneratedRound={round?.roundData?.isSystemGenerated}
						/>
					);
				})}
			</div>
		</>
	);
};

const UpdateRound = ({ values, isSystemGeneratedRound }) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		resetField,
	} = useForm({
		defaultValues,
		values: values,
		resolver: zodResolver(roundSchema),
	});
	const [updateRound, { isLoading }] = useUpdateRoundMutation();
	const handleUpdateRoundData = async (data) => {
		try {
			if (data.name === values.name) {
				return;
			}
			const res = await updateRound(data).unwrap();
			toast.success("Updated round name ");
		} catch (error) {
			const message = error?.data?.message || error.message;
			toast.warn(message);
			resetField("name"); // come back here
		}
	};
	return (
		<form
			className="flex flex-row gap-2 items-center create-update-opening flex-wrap"
			noValidate
			onSubmit={handleSubmit(handleUpdateRoundData)}
		>
			<div className="flex-1 flex flex-col gap-1">
				<input
					type="text"
					id={`update-round-${values._id}`}
					{...register("name")}
					disabled={isSystemGeneratedRound || isLoading}
					className="mt-1 w-full rounded-md border p-2 disabled:opacity-50 "
					placeholder="Enter Round name"
					title={isSystemGeneratedRound ? "Cannot edit a system generated round" : ""}
				/>
				<ErrorMessage errorCriteria={errors?.name?.message} />
			</div>
			<CustomButton
				type="submit"
				onClick={handleSubmit(handleUpdateRoundData)}
				disabled={isSystemGeneratedRound || isLoading}
			>
				{isLoading ? "Saving..." : "Save"}
			</CustomButton>
		</form>
	);
};
