import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MicOffIcon from "@mui/icons-material/MicOff";

const MutedMicModal = ({ showModal, setShowModal }) => {
	return (
		<Modal
			open={showModal}
			onClose={() => setShowModal(false)}
			aria-labelledby="mic-issue-modal-title"
			aria-describedby="mic-issue-modal-description"
		>
			<Box
				className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[400px] bg-white p-6 rounded-lg shadow-lg border-none outline-none"
				sx={{
					"& .MuiBackdrop-root": {
						outline: "none !important",
					},
					"&:focus-visible": {
						outline: "none !important",
					},
				}}
			>
				<div className="flex flex-col items-center">
					<MicOffIcon fontSize="medium" className="mb-3" />
					<Typography variant="h6" component="h2">
						Your mic has been muted by your system settings
					</Typography>
					<Typography className="mt-4">
						Please check your computer's settings to enable your microphone and adjust its volume.
					</Typography>
				</div>

				<Box className="flex justify-end mt-3">
					<Button
						className="normal-case cursor-pointer bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none focus:border-none text-xs"
						onClick={() => setShowModal(false)}
					>
						OK
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default MutedMicModal;
