import { toast } from "react-toastify";
import Button from "../QuestionBank/Button";
import { CircularProgress, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import {
	useGetRoundsForAnOrganizationQuery,
	useLinkRoundToOpeningMutation,
} from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const LinkRoundToOpening = ({ handleModalClose }) => {
	const { organizationId, openingId } = useParams();
	const { user } = useSelector((state) => state.auth);
	const { data, isLoading, isFetching } = useGetRoundsForAnOrganizationQuery(
		organizationId || user?.organizationId,
	);
	const [linkRoundToOpening, { isLoading: isLinkingRound }] = useLinkRoundToOpeningMutation();
	const [roundId, setRoundId] = useState("x");
	if (isLoading) {
		return <CircularProgress />;
	}

	const handleAddRoundToOpening = async (e) => {
		e.preventDefault();
		const data = {
			openingId,
			roundId,
		};
		try {
			const res = await linkRoundToOpening(data).unwrap();
			handleModalClose();
		} catch (error) {
			console.log(error);
			const message = error?.data?.message || error.message;
			toast.warn(message);
		} finally {
		}
	};
	return (
		<form className=" flex flex-col gap-4  p-5 ">
			<h4>Select a round to link it with the current opening</h4>
			<Select
				id={`${openingId}__select_round`}
				value={roundId}
				onChange={(e) => setRoundId(e.target.value)}
				sx={{
					"& .MuiOutlinedInput-notchedOutline": {
						border: "1px solid rgb(229, 231, 235)",
					},
					"& .MuiSelect-select": {
						padding: "3px 5px",
					},
					"&:hover .MuiOutlinedInput-notchedOutline": {
						border: "1px solid rgb(229, 231, 235)",
					},
				}}
				MenuProps={{
					PaperProps: {
						sx: {
							borderRadius: "5px",
							boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
						},
					},
				}}
			>
				<MenuItem value={"x"} selected={true} disabled={true}>
					-- Select A Round --
				</MenuItem>
				{data?.rounds?.map((round) => {
					const { _id, name } = round;

					return (
						<MenuItem key={_id} value={_id} className="border-none outline-none text-sm">
							{name}
						</MenuItem>
					);
				})}
			</Select>

			<Button
				type="submit"
				disabled={roundId === "x" || isLinkingRound || isLoading || isFetching}
				onClick={handleAddRoundToOpening}
			>
				Add Round To Opening
			</Button>
		</form>
	);
};

export default LinkRoundToOpening;
