import { Box, Tabs, Tab } from "@mui/material";
import React from "react";
import { useGetRoundsForAnOpeningQuery } from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addFilterAction, addRoundId, removeAllFilters } from "../../app/actions/filter/filter";

import RoundEditOptions from "./RoundEditOptions";
import AddEditRound from "./AddEditRound";

function a11yProps(index) {
	return {
		id: `opening-round-tab-${index}`,
		"aria-controls": `opening-round-tabpanel-${index}`,
	};
}

const OpeningRoundTabs = () => {
	const { selectedRoundId } = useSelector((state) => state.filters);
	const { openingId } = useParams();
	const { data } = useGetRoundsForAnOpeningQuery(openingId);
	const dispatch = useDispatch();

	const handleFilterRecords = (roundId) => {
		if (roundId === null) {
			dispatch(removeAllFilters());
		} else {
			const criteria = [{ type: "row", column: "Round", operator: "=", value: roundId }];
			dispatch(addRoundId(roundId));
			dispatch(addFilterAction(criteria));
		}
	};

	const handleChange = (e, newVal) => {
		handleFilterRecords(newVal);
	};

	return (
		<div className="flex flex-row gap-4 flex-wrap items-center justify-between w-full mb-2 lg:mb-0 ">
			<Box
				sx={{
					borderBottom: 1,
					borderColor: "divider",
					flex: 1,
					maxWidth: { xs: 280, sm: "80%", lg: "fit-content" },
				}}
			>
				<Tabs
					value={selectedRoundId}
					onChange={handleChange}
					aria-label="opening rounds tabs"
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
				>
					<Tab label={"Candidates"} className="text-gray-800" {...a11yProps("all")} value={null} />
					{/* <Tab
						label={"Completed Interviews"}
						{...a11yProps("all")}
						value={"completed-interviews"}
					/> */}
					{data?.rounds?.map((round) => {
						const {
							_id,
							roundId,
							roundData: { name },
							count,
						} = round;
						return (
							<Tab
								className="text-gray-800"
								label={name + ` (${count})`}
								key={_id}
								{...a11yProps(roundId)}
								value={roundId}
							/>
						);
					})}
				</Tabs>
			</Box>
			<AddEditRound />
			<div className="flex flex-row gap-2 items-end ml-auto flex-shrink-0">
				<RoundEditOptions />
			</div>
		</div>
	);
};

export default OpeningRoundTabs;
