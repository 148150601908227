import React, { useState } from "react";
import { Box, Modal, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { customFieldsModalStyles } from "../../utilities/constants";
import SortRounds from "./SortRounds";
import { EditRoundsForm } from "./AddEditRound";

const RoundEditOptions = () => {
	const [option, setOption] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);

	const handleOpenMenu = (e) => {
		setAnchorEl(e.target);
	};
	const handleClose = (e) => {
		setAnchorEl(null);
		setOption(null);
	};
	const handleOpenModal = (e, type) => {
		setOption(type);
	};
	const menuOpen = Boolean(anchorEl);
	return (
		<div className="flex-shrink-0">
			<IconButton onClick={handleOpenMenu}>
				<MoreVert />
			</IconButton>
			<Menu
				id="round-options-menu"
				anchorEl={anchorEl}
				open={menuOpen}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "round-options-menu",
				}}
			>
				<MenuItem
					onClick={(e) => {
						handleOpenModal(e, "edit");
					}}
				>
					Edit Rounds
				</MenuItem>
				<MenuItem
					onClick={(e) => {
						handleOpenModal(e, "reorder");
					}}
				>
					Reorder Rounds
				</MenuItem>
			</Menu>
			<Modal open={!!option} onClose={handleClose}>
				<Box sx={customFieldsModalStyles}>
					{option === "edit" && <EditRoundsForm handleModalClose={handleClose} />}
					{option === "reorder" && <SortRounds handleModalClose={handleClose} />}
				</Box>
			</Modal>
		</div>
	);
};

export default RoundEditOptions;
