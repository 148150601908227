import {
	Box,
	Button,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Modal,
	Typography,
} from "@mui/material/node";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import { getUploadCandidateSampleDownload, uploadCandidates } from "../../utilities/import";
import { clsx } from "clsx";

const UploadCandidates = () => {
	const { selectedRoundId } = useSelector((state) => state.filters);
	const openingData = useSelector((state) => state.openingDetails.selectedOpening);
	const [selectedFile, setSelectedFile] = useState(null);
	const [uploadStates, setUploadStates] = useState({
		uploadModal: false,
		bulkUploading: false,
	});
	const supportedFileTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

	const onDrop = useCallback(
		(acceptedFiles) => {
			if (selectedFile) {
				toast.error("Only one file is allowed at a time");
				return;
			}

			const file = acceptedFiles[0];
			if (file && supportedFileTypes.includes(file.type)) {
				setSelectedFile(file);
			} else {
				toast.error(`The file type is not supported. Please upload an Excel file.`);
			}
		},
		[selectedFile],
	);

	const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
		onDrop,
		noClick: true,
		accept: supportedFileTypes.join(","),
	});

	const removeFile = () => {
		setSelectedFile(null);
	};

	const bulkResumeUpload = async () => {
		if (!selectedFile) {
			toast.error("Please select a file to upload.");
			return;
		}
		setUploadStates({ ...uploadStates, bulkUploading: true });
		const formData = new FormData();
		formData.append("excel", selectedFile);
		formData.append("openingId", openingData._id);
		formData.append("organizationId", openingData.organizationId);

		const res = await uploadCandidates(formData);
		console.log(res);
		if (res?.message && res?.data) {
			setUploadStates({ ...uploadStates, bulkUploading: false });
			setSelectedFile(null);
			setUploadStates({ ...uploadStates, uploadModal: false });
			toast.success(res.message);
		}
		if (res?.failed && res?.message) {
			toast.error(res.message);
			setUploadStates({ ...uploadStates, bulkUploading: false });
			setSelectedFile(null);
			setUploadStates({ ...uploadStates, uploadModal: false });
		}
		document.body.style.overflow = "auto";
	};

	const sampleFile = async () => {
		try {
			const response = await getUploadCandidateSampleDownload();
			if (response.data) {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `zinterview_upload_candidates_sample.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<>
			<Button
				className={clsx(
					"bg-orange-500 hover:bg-orange-700 border-none normal-case focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between cursor-pointer",
					selectedRoundId ? "overflow-hidden invisible h-0 w-0 p-0 m-0" : "visible px-4 py-2",
				)}
				onClick={() => {
					setSelectedFile(null);
					setUploadStates({ ...uploadStates, uploadModal: true });
					document.body.style.overflow = "hidden";
				}}
			>
				<i className="fas fa-upload mr-2"></i>
				Upload Candidates
			</Button>
			<Modal
				open={uploadStates.uploadModal}
				onClose={() => {
					if (!uploadStates.bulkUploading) {
						setUploadStates({ ...uploadStates, uploadModal: false });
						document.body.style.overflow = "auto";
					}
				}}
				aria-labelledby="bulk-resume"
				aria-describedby="bulk-resume-upload"
				disableEscapeKeyDown={uploadStates.bulkUploading}
				sx={{
					overflow: "auto",
				}}
			>
				<Box className="w-full max-w-md mx-auto mt-10 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%]">
					<Box className="bg-white p-6 rounded-lg shadow-lg">
						<Typography component="h2" variant="h2" className="text-lg font-semibold mb-4">
							Upload Candidates
						</Typography>
						<Box
							className={`flex justify-center items-center w-full ${uploadStates.bulkUploading && "cursor-not-allowed"}`}
							{...(uploadStates.bulkUploading ? {} : getRootProps())}
						>
							<label
								htmlFor="file-upload"
								className={`flex flex-col justify-center items-center w-full h-32 border-2 border-gray-300 ${
									uploadStates.bulkUploading
										? "bg-gray-200 cursor-not-allowed"
										: `${
												isFocused && "border-blue-600"
											} ${isDragAccept && "border-green-600"} ${
												isDragReject && "border-red-600"
											} border-dashed cursor-pointer`
								}`}
								rounded-lg
							>
								<Box className="text-center">
									{isDragActive ? (
										<p className="mt-1 text-sm text-gray-500">Drop the file here...</p>
									) : (
										<p className="mt-1 text-sm text-gray-500">
											Drag and Drop here
											<br />
											or
											<br />
											<span
												className={`${
													uploadStates.bulkUploading
														? "text-gray-500"
														: "text-blue-600 hover:underline"
												}`}
											>
												Browse file
											</span>
										</p>
									)}
									<input
										{...(uploadStates.bulkUploading ? {} : getInputProps())}
										id="file-upload"
										name="file-upload"
										type="file"
										className="hidden"
										disabled={uploadStates.bulkUploading}
									/>
								</Box>
							</label>
						</Box>
						<List>
							{selectedFile && (
								<ListItem className="h-[30px]">
									<ListItemText primary={selectedFile.name} />
									<ListItemSecondaryAction>
										<IconButton
											disabled={uploadStates.bulkUploading}
											className={`
												${uploadStates.bulkUploading && "cursor-not-allowed opacity-50"}
											`}
											edge="end"
											aria-label="cancel"
											onClick={removeFile}
										>
											<CancelIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							)}
						</List>
						<Typography variant="p" component="p" className="text-xs text-gray-500 mt-2">
							Only Excel files are accepted
						</Typography>
						<Box
							component={Button}
							disabled={uploadStates.bulkUploading}
							className={`normal-case mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 cursor-pointer focus:outline-none focus:border-none text-center ${
								uploadStates.bulkUploading && "cursor-not-allowed opacity-50"
							}`}
							onClick={bulkResumeUpload}
						>
							{uploadStates.bulkUploading ? (
								<Box className="flex justify-center items-center">
									Uploading...
									<i id="" className="fas fa-circle-notch fa-spin text-base ml-2"></i>
								</Box>
							) : (
								"Upload"
							)}
						</Box>
						<Box
							component={Button}
							className={`normal-case mt-4 w-full bg-yellow-600 text-white py-2 px-4 rounded-lg hover:bg-yellow-700 cursor-pointer focus:outline-none focus:border-none text-center`}
							onClick={sampleFile}
						>
							Download Sample File
						</Box>
					</Box>
				</Box>
			</Modal>
		</>
	);
};

export default UploadCandidates;
