import React, { useState } from "react";
import { Box, IconButton, Popover } from "@mui/material";
import EventsModal from "../EventsModal";
import ActionsMenu from "../ActionsMenu";
import { useSelector } from "react-redux";
import DownloadCandidates from "../downloadCandidates/DownloadCandidates";
import AddCustomFeilds from "./AddCustomFeilds";
import AddCandidates from "./AddCandidates";
import UploadResumes from "./UploadResumes";
import { MoreVert } from "@mui/icons-material";
import UploadCandidates from "./UploadCandidates";

const ToolBar = () => {
	const { user } = useSelector((state) => state.auth);
	const { selectedRoundId } = useSelector((state) => state.filters);

	return (
		<>
			<Box className="flex justify-between items-center mb-4">
				<Box className="flex items-center justify-end flex-wrap gap-2 text-sm ml-2">
					{!selectedRoundId && (
						<>
							<AddCandidates />
							<UploadResumes />
							{user?.role === "1096" && <UploadCandidates />}
						</>
					)}
					<MoreActions />
				</Box>
			</Box>
		</>
	);
};

export default ToolBar;

const MoreActions = () => {
	const [anchorEl, setAnchorEl] = useState(false);
	const open = !!anchorEl;
	const handleClose = () => {
		setAnchorEl(null);
	};
	const { loading: fetchingOpeningData } = useSelector((state) => state.openingDetails);
	const { isFetchingCandidates: loadingReports, candidates } = useSelector((state) => state.candidates);
	const { selectedRoundId } = useSelector((state) => state.filters);
	return (
		<>
			<IconButton
				onClick={(e) => {
					setAnchorEl(e.target);
				}}
			>
				<MoreVert />
			</IconButton>
			<Popover
				id={"opening-more-actions"}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				{fetchingOpeningData || loadingReports ? null : (
					<div className="p-2 candidate__options">
						{!selectedRoundId && <AddCustomFeilds />}
						{!selectedRoundId && <EventsModal onEventModalClose={(data) => null} />}
						{!selectedRoundId && <ActionsMenu />}
						{(candidates && candidates.length) > 0 && <DownloadCandidates />}
					</div>
				)}
			</Popover>
		</>
	);
};
