import { AutoAwesome, AutoFixHigh, Close, Delete, Info, Mail, Preview } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, MenuItem, Modal, TextField, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { EMAIL_TEMPLATE } from "../events/constants";
import EmailEditorComponent from "../events/actions/EmailEditor";
import getFieldsArray from "../../utilities/getFieldsArray";
import {
	addTemplate,
	deleteTemplateApi,
	generateTemplate,
	getTemplates,
	sendEmail,
	// updateTemplate,
} from "../../utilities/templates";
import { toast } from "react-toastify";
import { PreviewModal } from "../events/actions/EmailFormRenderer";
import MultipleSelectChip from "./MultiSelectComponent";
import { renderConfirmationDialog } from "../confirmation/ConfirmationDialog";

const SendEmail = ({ onSendEmailModalClose = () => null }) => {
	const [modalState, setModalState] = useState({
		openModal: false,
	});

	const {
		candidates: { selectedCandidate, count, candidates, showHiddenCandidates },
		openingDetails: {
			selectedOpening: { _id: openingId, customFields },
		},
	} = useSelector((state) => state);

	const filteredInterviewReports = candidates.filter((report) => selectedCandidate.includes(report._id));

	const [sendingEmail, setSendingEmail] = useState(false);
	const [generatedTemplate, setGeneratedTemplate] = useState({
		template: "",
		subject: "",
	});
	const [showPreview, setShowPreview] = useState(false);
	const [showAiModal, setShowAiModal] = useState(false);
	const [savingTemplate, setSavingTemplate] = useState(false);
	const [deletingTemplate, setDeletingTemplate] = useState(false);
	const [selectedTemplate, setSelectedTemplate] = useState(null);
	const [templates, setTemplates] = useState([]);
	const [loadingTemplates, setLoadingTemplates] = useState(false);
	const [emailData, setEmailData] = useState(JSON.parse(JSON.stringify(EMAIL_TEMPLATE)));
	const [selectedEmails, setSelectedEmails] = useState([]);

	const onClose = () => {
		setModalState({ ...modalState, openModal: false });
		onSendEmailModalClose();
	};

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;
		setSelectedEmails(typeof value === "string" ? value.split(",") : JSON.parse(JSON.stringify(value)));
	};

	const toEmails = candidates
		.filter((el) => !!el.hidden === showHiddenCandidates)
		.map((report) => {
			return { email: report.email, id: report._id };
		});

	const fetchTemplates = async () => {
		setLoadingTemplates(true);
		try {
			const response = await getTemplates();
			if (response && response.data) {
				setTemplates(response.data);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingTemplates(false);
			setEmailData(JSON.parse(JSON.stringify(EMAIL_TEMPLATE)));
		}
	};

	useEffect(() => {
		if (modalState.openModal) {
			fetchTemplates();
		}
	}, [modalState.openModal]);

	useEffect(() => {
		handleChange({ target: { value: filteredInterviewReports.map((el) => el.email) } });
	}, [count]);

	const saveTemplate = async () => {
		if (!emailData.subject || !emailData.body) {
			toast.error("Subject and body are required");
			return;
		}
		setSavingTemplate(true);
		try {
			let templateFunction = addTemplate;
			const response = await templateFunction({
				data: {
					subject: emailData.subject,
					body: emailData.body,
					templateName: emailData.subject,
					// id: emailData._id,
				},
			});
			if (response && response.data) {
				setTemplates([...templates, response.data]);
				toast.success(
					emailData._id ? "Template updated successfully" : "Template saved successfully",
				);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setSavingTemplate(false);
		}
	};

	const sendMail = () => {
		renderConfirmationDialog(
			"Are you sure you want to send this email? Please preview emails before sending using the preview icon and ensure all variables are correctly replaced.",
			() => null,
			sendEMail,
			true,
			5000,
			"Send Email",
		);
	};

	const sendEMail = async () => {
		if (selectedEmails.length <= 0 || !emailData.subject || !emailData.body) {
			toast.error("To, Subject and body are required");
			return;
		}
		try {
			setSendingEmail(true);
			let emails = candidates
				.filter((report) => selectedEmails.includes(report.email))
				.map((report) => report._id);
			let response = await sendEmail({ ...emailData, to: emails.join(",") });
			if (response && response.data) {
				toast.success("Email sent successfully");
			} else {
				toast.error("Error sending email");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setSendingEmail(false);
			onClose();
		}
	};

	const onClickTemplateName = (e) => {
		const template = templates.find((el) => el._id === e.target.value);
		if (template) {
			setEmailData(template);
			setSelectedTemplate(template);
		} else {
			setSelectedTemplate(null);
		}
	};

	const onDeleteTemplate = async () => {
		renderConfirmationDialog(
			"Are you sure you want to delete this template?",
			() => null,
			deleteTemplate,
			true,
			0,
		);
	};

	const deleteTemplate = async () => {
		if (!selectedTemplate) {
			toast.error("No template selected");
			return;
		}
		try {
			setDeletingTemplate(true);
			let response = await deleteTemplateApi({ id: selectedTemplate._id });
			if (response && response.data) {
				setTemplates(templates.filter((el) => el._id !== selectedTemplate._id));
				setEmailData(JSON.parse(JSON.stringify(EMAIL_TEMPLATE)));
				setSelectedTemplate(null);
				toast.success("Template deleted successfully");
			} else {
				toast.error("Error deleting template");
			}
		} catch (error) {
			console.log(error);
		} finally {
			setDeletingTemplate(false);
		}
	};

	const onClickGenerateWithAi = () => {
		setShowAiModal(true);
	};

	const afterTemplateGeneration = async (template) => {
		setGeneratedTemplate(template);
	};

	const useGeneratedTemplate = () => {
		if (!generatedTemplate.template) {
			toast.error("Generated template is empty.");
			return;
		}

		let blocks = generatedTemplate.template.split("\n\n").map((el) => {
			el = el.replace(/\n/g, "<br>");
			return {
				type: "paragraph",
				data: {
					text: el,
				},
			};
		});

		setEmailData({
			...emailData,
			subject: generatedTemplate.subject,
			body: JSON.stringify({
				time: new Date().getTime(),
				blocks: blocks,
			}),
		});
		setShowAiModal(false);
		setGeneratedTemplate({
			template: "",
			subject: "",
		});
	};

	return (
		<div>
			<Tooltip title="Send Emails">
				<Button
					onClick={() => setModalState({ ...modalState, openModal: true })}
					variant="contained"
					color="primary"
					startIcon={<Mail />}
					disabled={count === 0}
					className="normal-case bg-indigo-500 rounded-md shadow-none focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white flex items-center justify-between disabled:opacity-50"
				>
					Email ({count})
				</Button>
			</Tooltip>
			{modalState.openModal && (
				<Modal
					open={modalState.openModal}
					onClose={onClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<div
						className="bg-white rounded-lg shadow-lg flex"
						style={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: "70vw",
							height: "85vh",
							bgcolor: "background.paper",
							boxShadow: 24,
						}}
					>
						<div className="flex flex-col h-full w-full p-6">
							<div className="flex justify-between items-center pb-3">
								<h1 className="text-lg font-bold">Email</h1>
								<IconButton onClick={onClose}>
									<Close className="scale-90" />
								</IconButton>
							</div>
							<div className="flex flex-col my-2">
								<div className="text-sm text-gray-800 font-semibold">Load Template:</div>
								<div className="flex items-center gap-1">
									<TextField
										select
										fullWidth
										variant="outlined"
										size="small"
										onChange={onClickTemplateName}
										inputProps={{ className: "text-sm" }}
									>
										{templates.length > 0 ? (
											templates.map((option) => (
												<MenuItem
													key={option._id}
													value={option._id}
													className="text-sm"
												>
													{option.templateName}
												</MenuItem>
											))
										) : (
											<MenuItem value="0" className="text-sm">
												No Templates Found
											</MenuItem>
										)}
									</TextField>
									{selectedTemplate && (
										<Tooltip title="Delete Template">
											<IconButton
												onClick={onDeleteTemplate}
												disabled={deletingTemplate}
												className="text-rose-500"
											>
												<Delete />
											</IconButton>
										</Tooltip>
									)}
								</div>
							</div>
							<div className="flex flex-col my-2">
								<div className="text-sm text-gray-800 font-semibold">To:</div>
								<MultipleSelectChip
									options={toEmails}
									personName={selectedEmails}
									handleChange={handleChange}
								/>
							</div>
							<div className="flex flex-col my-2">
								<div className="text-sm text-gray-800 font-semibold">Subject:</div>
								<TextField
									fullWidth
									variant="outlined"
									size="small"
									value={emailData.subject}
									onChange={(e) =>
										setEmailData({
											...emailData,
											subject: e.target.value,
										})
									}
									inputProps={{ className: "text-sm" }}
								/>
							</div>
							<div className="text-sm text-gray-800 mt-2 font-semibold relative w-full">
								Message:
								<div
									className="flex gap-1 -top-1 -right-0.5 z-20 absolute bg-white px-2"
									style={{ border: "2px solid #f2f3f5" }}
								>
									{showPreview && (
										<PreviewModal
											open={showPreview}
											action={emailData}
											onClose={() => setShowPreview(false)}
											openingId={openingId}
										/>
									)}
									<Tooltip title="Preview">
										<IconButton color="warning" onClick={() => setShowPreview(true)}>
											<Preview />
										</IconButton>
									</Tooltip>
									<Tooltip title={<InfoRenderer fields={getFieldsArray(customFields)} />}>
										<IconButton color="info">
											<Info />
										</IconButton>
									</Tooltip>
									{showAiModal && (
										<GenerateWithAiModal
											open={showAiModal}
											onClose={() => {
												setShowAiModal(false);
												setGeneratedTemplate({
													template: "",
													subject: "",
												});
											}}
											afterGeneration={afterTemplateGeneration}
											onUseTemplate={useGeneratedTemplate}
											generatedTemplate={generatedTemplate.template}
										/>
									)}
									<Tooltip title="Generate with AI">
										<IconButton
											className="text-fuchsia-600 animate-pulse"
											onClick={onClickGenerateWithAi}
										>
											<AutoAwesome />
										</IconButton>
									</Tooltip>
								</div>
							</div>
							<div
								style={{
									maxHeight: "40vh",
									overflow: "auto",
									border: "1px solid #f2f3f5",
								}}
								className="flex flex-col mb-2 rounded-md h-full relative overflow-x-hidden"
							>
								{loadingTemplates ? (
									<div className="w-full h-full flex justify-center items-center">
										<CircularProgress />
									</div>
								) : (
									!showAiModal &&
									modalState.openModal && (
										<EmailEditorComponent
											action={emailData}
											onChange={(content) =>
												setEmailData((prev) => ({ ...prev, body: content }))
											}
											index={emailData._id || emailData.id}
											style={{ zIndex: 5 }}
										/>
									)
								)}
							</div>
							<div className="flex-1" />
							<div className="flex justify-end mt-1 gap-2">
								<div
									variant="contained"
									color="warning"
									onClick={saveTemplate}
									disabled={savingTemplate || sendingEmail}
									className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600 text-sm cursor-pointer"
								>
									{selectedTemplate && selectedTemplate?._id && false
										? "Update Template"
										: "Save Template"}
								</div>
								<div
									variant="contained"
									color="primary"
									onClick={sendMail}
									disabled={savingTemplate || sendingEmail}
									className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 text-sm cursor-pointer"
								>
									Send
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default SendEmail;

const InfoRenderer = ({ fields, type = 2 }) => {
	return (
		<div className="pl-3 text-sm">
			<ol>
				{type == 1 && <li>Use JSON editor mode to add complex nested data.</li>}
				<li>{"To use a column just wrap the column name in '<=' and '=>'"}.</li>
				<li>{"Example: <=Interview date=>"}.</li>
				<li>
					Available variables:{" "}
					{fields
						.filter((el) => el.enabledFor.includes("info"))
						.map((el, index) => {
							return (
								<span key={index}>
									{" "}
									{el.label ? el.label : el.value}
									{index === fields.length - 1 ? "." : ","}{" "}
								</span>
							);
						})}
				</li>
			</ol>
		</div>
	);
};

const GenerateWithAiModal = ({ open, onClose, onUseTemplate, generatedTemplate, afterGeneration }) => {
	const [generating, setGenerating] = useState(false);
	const [form, setForm] = useState({
		prompt: "",
		toneOfVoice: "Friendly",
		textLength: "Short",
	});

	const generateTemplateFromAi = async () => {
		if (!form.prompt || form.prompt.trim() === "") {
			toast.error("Please provide a prompt to generate the email.");
			return;
		}
		try {
			setGenerating(true);
			let response = await generateTemplate(form);
			if (response && response.data && response.data.content) {
				let mail = JSON.parse(response.data.content);
				let template = mail.template;
				let subject = mail.subject;
				afterGeneration({ template, subject });
			}
		} catch (error) {
			console.log(error);
		} finally {
			setGenerating(false);
		}
	};

	const toneOfVoices = [
		"Friendly",
		"Professional",
		"Formal",
		"Casual",
		"Confident",
		"Empathetic",
		"Humorous",
		"Urgent",
		"Encouraging",
		"Apologetic",
	];

	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div
				className="bg-white rounded-lg shadow-lg flex"
				style={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "65vw",
					// height: "85vh",
					bgcolor: "background.paper",
					boxShadow: 24,
				}}
			>
				<div className="flex flex-col h-full w-full p-6">
					<div className="flex justify-between items-center pb-3">
						<h1 className="text-lg font-bold flex justify-center items-center gap-2">
							<AutoAwesome color="primary" className={generating ? "animate-bounce" : ""} />{" "}
							Generate with AI
						</h1>
						<IconButton onClick={onClose}>
							<Close className="scale-90" />
						</IconButton>
					</div>
					<div className="flex flex-col my-2 gap-2">
						<div>
							<div className="text-sm text-gray-800 font-semibold">Prompt:</div>
							<TextField
								placeholder="Write a prompt for AI"
								fullWidth
								multiline
								rows={2}
								variant="outlined"
								size="small"
								value={form.prompt}
								onChange={(e) => setForm({ ...form, prompt: e.target.value })}
								inputProps={{ className: "text-sm" }}
							/>
						</div>
						<div>
							<div className="text-sm text-gray-800 mt-2 font-semibold">Tone of Voice:</div>
							<TextField
								select
								fullWidth
								variant="outlined"
								size="small"
								value={form.toneOfVoice}
								onChange={(e) => setForm({ ...form, toneOfVoice: e.target.value })}
								inputProps={{ className: "text-sm" }}
							>
								{toneOfVoices.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						</div>
						<div>
							<div className="text-sm text-gray-800 mt-2 font-semibold">Text Length:</div>
							<TextField
								select
								fullWidth
								variant="outlined"
								size="small"
								value={form.textLength}
								onChange={(e) => setForm({ ...form, textLength: e.target.value })}
								inputProps={{ className: "text-sm" }}
							>
								{["Short", "Medium", "Long"].map((option) => (
									<MenuItem key={option} value={option} className="text-sm">
										{option}
									</MenuItem>
								))}
							</TextField>
						</div>
						{!generating ? (
							generatedTemplate !== "" && generatedTemplate != null ? (
								<div
									className="w-full overflow-auto h-[30vh] rounded-md p-2 mt-2 break-words whitespace-pre-wrap text-sm"
									dangerouslySetInnerHTML={{ __html: generatedTemplate }}
									style={{ border: "1px solid #f2f3f5" }}
								></div>
							) : (
								<div className="w-full h-[30vh] rounded-md p-2 mt-2 break-words whitespace-pre-wrap flex flex-col justify-center items-center text-gray-500">
									<div
										variant="contained"
										onClick={generateTemplateFromAi}
										// startIcon={<AutoFixHigh />}
										disabled={generating}
										className="bg-gradient-to-r from-indigo-500 hover:from-indigo-600 to-fuchsia-600 hover:to-fuchsia-700 text-white pr-4 pl-3 py-2 rounded-md text-sm cursor-pointer flex items-center justify-center gap-1"
									>
										<AutoFixHigh className="scale-75" />
										Generate
									</div>
								</div>
							)
						) : (
							<div className="w-full h-[30vh] rounded-md p-2 mt-2 break-words whitespace-pre-wrap flex flex-col justify-center items-center">
								<CircularProgress />
							</div>
						)}
						<div className="flex justify-end items-end mt-1 gap-2">
							<p className="text-sm text-gray-500 flex items-center">
								<Info className="scale-75" /> Click use template to edit the generated content
							</p>
							<div className="flex-1" />
							{generatedTemplate !== "" && generatedTemplate != null && (
								<div
									variant="contained"
									disabled={generating}
									onClick={generateTemplateFromAi}
									startIcon={<AutoFixHigh />}
									className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 text-sm cursor-pointer flex items-center justify-center gap-1"
								>
									{/* <AutoFixHigh className="scale-75" /> */}
									Regenerate
								</div>
							)}
							<div
								variant="contained"
								onClick={onUseTemplate}
								disabled={generating}
								className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 text-sm cursor-pointer"
							>
								Use
							</div>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export { InfoRenderer, GenerateWithAiModal };
