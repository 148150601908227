export const availableLanguagesMap = {
	// af: "Afrikaans",
	en: "English",
	ar: "Arabic",
	// bg: "Bulgarian",
	// ca: "Catalan",
	// cs: "Czech",
	// da: "Danish",
	// nl: "Dutch",
	// fi: "Finnish",
	fr: "French",
	// gl: "Galician",
	// de: "German",
	// el: "Greek",
	// he: "Hebrew",
	hi: "Hindi",
	// hu: "Hungarian",
	// is: "Icelandic",
	id: "Indonesian",
	it: "Italian",
	ja: "Japanese",
	kn: "Kannada",
	ko: "Korean",
	// lv: "Latvian",
	// lt: "Lithuanian",
	ms: "Malay",
	mr: "Marathi",
	// no: "Norwegian",
	pl: "Polish",
	// pt: "Portuguese",
	// ro: "Romanian",
	ru: "Russian",
	// sr: "Serbian",
	// sk: "Slovak",
	es: "Spanish",
	// sv: "Swedish",
	ta: "Tamil",
	th: "Thai",
	// tr: "Turkish",
	// uk: "Ukrainian",
	// vi: "Vietnamese",
};

export const translations = {
	ar: "لغة المقابلة",
	fr: "Langue d'entretien",
	hi: "इंटरव्यू भाषा",
	id: "Bahasa Wawancara",
	it: "Lingua del colloquio",
	ja: "面接の言語",
	kn: "ಮೂಲ್ಕಾತಿ ಭಾಷೆ",
	ko: "면접 언어",
	ms: "Bahasa Temuduga",
	mr: "मुलाखत भाषा",
	pl: "Język rozmowy kwalifikacyjnej",
	ru: "Язык собеседования",
	es: "Idioma de la entrevista",
	ta: "நேர்காணல் மொழி",
	th: "ภาษาในการสัมภาษณ์",
};

export const allLanguagesWithISO639Codes = Object.keys(availableLanguagesMap);

export const promptHelperForLanguages = (languageCode = "en") => {
	const language = availableLanguagesMap[languageCode] ?? "English";
	const promptToReturn = language === "English" ? "English language" : `${language} and English languages`;
	return promptToReturn;
};

export const availableCustomOptions = {
	0: "Text",
	1: "Drop Down",
};

export const availableColors = [
	"bg-green-300",
	"bg-blue-300",
	"bg-red-300",
	"bg-indigo-300",
	"bg-orange-300",
	"bg-violet-300",
	"bg-pink-300",
	"bg-teal-300",
	"bg-sky-300",
	"bg-amber-300",
	"bg-gray-300",
	"bg-fuchsia-300",
	"bg-purple-300",
	"bg-rose-300",
	"bg-cyan-300",
	"bg-slate-300",
	"bg-red-500",
	"bg-emerald-300",
];

export const dummySqlTable = `
EmployeeID | FirstName | LastName | DepartmentID | Salary
1          | John      | Doe      | 1            | 50000
2          | Jane      | Smith    | 2            | 60000
3          | Jim       | Brown    | 1            | 55000
4          | Jake      | Wilson   | 3            | 70000
5          | Jill      | Johnson  | 2            | 65000

DepartmentID | DepartmentName
1            | HR
2            | IT
3            | Finance

1. Write a query to retrieve the full names (FirstName and LastName) and department names of all employees.
2. Write a query to find the average salary of employees in each department.
    `;

export const zinterviewImageUrl =
	"https://machinecon.analyticsindiamag.com/wp-content/uploads/2024/06/zinterview-logo.png";

export const difficultyLevels = { Easy: "Basic", Normal: "Intermediate", Hard: "Advanced" };

const STRICT = "Moderately stringent evaluation";
const MODERATE = "Standard evaluation";

export const scoringLeniencyMap = {
	1: STRICT,
	2: MODERATE,
};

export const CONSENT_TYPES = {
	ALL: "all",
	WELCOME: "welcome",
	CHEATING: "cheating",
};

export const pages = {
	CHECK_PERMISSIONS: "checkPermissions",
	DEVICE_TEST: "deviceTest",
	TOPICS_PICKER: "topicsPicker",
	INTERVIEW: "interview",
	CONFIRM_EMAIL: "confirmEmail",
	NETWORK_LOST: "networkLost",
	USE_PREVIOUS_DEVICE: "usePreviousDevice",
	MULTIPLE_TABS: "multipleTabs",
	INVALID_TOKEN: "invalidToken",
	RESUME_PROMPT: "resumePrompt",
	JOINED_MOBILE: "joinedMobile",
	JOINED_EARLY: "joinedEarly",
	CORRECTION_FORM: "correctionForm",
	WELCOME: "welcome",
	CHEATING_WARNING: "cheatingWarning",
	LOADING: "loading",
	MOBILE_NOT_ALLOWED: "mobileNotAllowed",
	CANCELLED: "cancelled",
	CAN_LEAVE: "canLeave",
	CANDIDATE_PHOTO_CAPTURE: "candidatePhotoCapture",
	TIMEOUT: "timeout",
	SD_CONTINUE: "sdContinue",
	IDENTITY_VERIFICATION: "identityVerification",
	INTERVIEW_LANGUAGE_OPTION: "INTERVIEW_LANGUAGE_OPTION",
};

export const customFieldsModalStyles = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "95%",
	"@media (min-width:600px)": {
		width: "80%",
	},
	"@media (min-width:960px)": {
		width: "70%",
	},
	"@media (min-width:1280px)": {
		width: "45%",
	},
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
	overflowY: "auto",
	maxHeight: "90vh",
	borderRadius: "10px",
};

export const SelectorStyles = {
	p: "3px",
	maxWidth: "50ch",
	flexBasis: "50ch",
	flexGrow: "0",
	"& .MuiOutlinedInput-notchedOutline": {
		border: "1px solid rgb(229, 231, 235)",
	},
	"& .MuiSelect-select": {
		padding: "3px 5px",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "1px solid rgb(229, 231, 235)",
	},
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
};
export const MenuItemStyles = {
	PaperProps: {
		sx: {
			borderRadius: "5px",
			boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.2)",
		},
	},
};
