import React from "react";
import { CriteriaComponent } from "../events/actions/CriteriaModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { addFilterAction } from "../../app/actions/filter/filter";
import getFieldsArray from "../../utilities/getFieldsArray";
import { FilterAlt } from "@mui/icons-material";
import { useGetRoundsForAnOpeningQuery } from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";

const FilterComponent = () => {
	const { criteria } = useSelector((state) => state.filters);
	const { selectedOpening: openingData } = useSelector((state) => state.openingDetails);
	const dispatch = useDispatch();
	const { openingId } = useParams();
	const { data } = useGetRoundsForAnOpeningQuery(openingId);
	const { user } = useSelector((state) => state.auth);
	const showRounds = user?.role === "1096";
	return (
		<CriteriaComponent
			action={{ criteria: criteria }}
			onCriteriaSave={(data) => {
				dispatch(addFilterAction(data));
			}}
			fields={getFieldsArray(openingData?.customFields, true, data?.rounds, showRounds).filter(
				(field) => field.enabledFor.includes("applyFilters"),
			)}
			buttonClasses="bg-sky-50 hover:bg-sky-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow-none"
			buttonLabel="Filters"
			buttonIcon={<FilterAlt className="text-blue-500" />}
			buttonVariant="contained"
			infoText="Rows will be filtered based on the criteria"
		/>
	);
};

export default FilterComponent;
