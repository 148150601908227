import * as React from "react";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
	hideHiddenCandidates,
	showHiddenCandidates as showHiddenCandidatesAction,
	showCompletedInterviews,
	showAllCandidates as showAllCandidatesActionCreator,
	selectAllCandidate,
} from "../../app/actions/candidates/candidate";
import useGetColumnsAndRenderers from "./getColumnsAndRenderers";
import { useGridApi } from "../../app/contexts/GridApiProvider";
import { onGridFilterModalChange } from "../../app/actions/filter/filter";
// import { getFilteredRecords } from "../Filter/getFilteredRecords";
import { getFilteredRecordsFromCriteria } from "../Filter/getFilteredRecordsFromCriteria";
import {
	useGetRoundsForAnOpeningQuery,
	useGetCheatingScoresForOpeningQuery,
} from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import HideUnhideButton from "../hideUnhideButton/HideUnhideButton";
import BulkPromote from "../Rounds/BulkPromote";
import SendEmail from "../directEmail/SendEmail";
import BulkScheduler from "../bulkSchedule/BulkScheduler";
import clsx from "clsx";
import FilterComponent from "../Filter/FilterComponent";

const ReportsDataGrid = () => {
	const ref = React.useRef(null);
	const { setGridApi } = useGridApi();
	const { openingId } = useParams();
	const {
		candidates,
		showHiddenCandidates,
		showCompletedInterviews,
		selectedCandidate,
		showAllCandidates,
	} = useSelector((state) => state.candidates);
	const { selectedOpening: openingData } = useSelector((state) => state.openingDetails);
	const { isFilterApplied, criteria, selectedRoundId } = useSelector((state) => state.filters);
	const dispatch = useDispatch();

	const onSelectionChange = (selection) => {
		dispatch(selectAllCandidate(selection));
	};
	const { data: cheatingScoresData, isLoading: isCheatingScoresLoading } =
		useGetCheatingScoresForOpeningQuery(openingId);
	const onFilterModelChange = (model) => {
		dispatch(onGridFilterModalChange(model.items));
	};

	const columns = useGetColumnsAndRenderers();

	const { data } = useGetRoundsForAnOpeningQuery(openingId);

	const memoisedReport = React.useMemo(() => {
		let filteredReports = candidates.map((candidate) => {
			return {
				...candidate,
				cheatingScore:
					!isCheatingScoresLoading && cheatingScoresData?.cheatingScores
						? cheatingScoresData?.cheatingScores?.[candidate?._id]
						: {},
			};
		});

		filteredReports = filteredReports.filter((el) => !!el.hidden === showHiddenCandidates);
		filteredReports = filteredReports.filter((report) => {
			return showAllCandidates || selectedRoundId ? true : !report.roundId;
		});
		if (showCompletedInterviews) {
			filteredReports = filteredReports.filter(
				(report) => report.interviewCompleted && !report.clickedExitInterview,
			);
		}
		if (isFilterApplied) {
			filteredReports = getFilteredRecordsFromCriteria(
				filteredReports,
				criteria,
				openingData,
				data?.rounds,
				selectedRoundId,
			);
		}

		return filteredReports;
	}, [
		candidates,
		showHiddenCandidates,
		showCompletedInterviews,
		isFilterApplied,
		criteria,
		openingData,
		cheatingScoresData,
		isCheatingScoresLoading,
		data,
		showAllCandidates,
		selectedRoundId,
	]);

	React.useEffect(() => {
		if (ref.current) {
			setGridApi(ref.current);
		}
	}, [ref, setGridApi]);

	return (
		<div style={{ height: "160vh", width: "100%" }}>
			<DataGrid
				apiRef={ref}
				rows={memoisedReport}
				getRowId={(row) => row._id}
				columns={columns}
				checkboxSelection
				disableRowSelectionOnClick
				rowHeight={80}
				getRowClassName={() => "hoverRow"}
				onRowSelectionModelChange={onSelectionChange}
				rowSelectionModel={selectedCandidate}
				onFilterModelChange={onFilterModelChange}
				slots={{
					toolbar: CustomGridToolbar,
				}}
				// filterMode="client"
				sx={{
					"& .MuiDataGrid-cell:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-columnHeader:focus": {
						outline: "none",
					},
					"& .MuiDataGrid-toolbarQuickFilter": {
						marginBottom: "16px",
						padding: "4px",
						margin: "4px",
						width: "30%",
						borderRadius: "4px",
						border: "1px solid #dcdcdc",
						alignSelf: "end",
					},
					"& .MuiInput-underline:before": {
						borderBottom: "none",
					},
					"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
						borderBottom: "none",
					},
					"& .MuiInput-underline:after": {
						borderBottom: "none",
					},
				}}
				slotProps={{
					toolbar: {
						csvOptions: {
							fields: [
								"createdAt",
								"firstName",
								"score",
								"trustScore",
								"interviewStatus",
								"resumeFileNameInS3",
								"concatenationId",
							],
						},
					},
					filterPanel: {
						filterFormProps: {
							logicOperatorInputProps: {
								variant: "outlined",
								size: "small",
							},
							columnInputProps: {
								variant: "outlined",
								size: "small",
								sx: { mt: "auto" },
							},
							operatorInputProps: {
								variant: "outlined",
								size: "small",
								sx: { mt: "auto" },
							},
							valueInputProps: {
								InputComponentProps: {
									variant: "outlined",
									size: "small",
								},
							},
							deleteIconProps: {
								sx: {
									"& .MuiSvgIcon-root": { color: "#d32f2f" },
								},
							},
						},
						sx: {
							"& .MuiDataGrid-filterForm": { p: 2 },
							"& .MuiDataGrid-filterForm:nth-child(even)": {
								backgroundColor: (theme) =>
									theme.palette.mode === "dark" ? "#444" : "#f5f5f5",
							},
							"& .MuiDataGrid-filterFormLogicOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormColumnInput": { mr: 2, width: 150 },
							"& .MuiDataGrid-filterFormOperatorInput": { mr: 2 },
							"& .MuiDataGrid-filterFormValueInput": { width: 200 },
						},
					},
				}}
			/>
		</div>
	);
};

export default ReportsDataGrid;

const CustomGridToolbar = () => {
	return (
		<GridToolbarContainer className="bg-slate-200 flex flex-col gap-4">
			<MoreOptions />
			<div className="flex flex-row items-center justify-between w-full gap-2 flex-wrap">
				<CandidateSelectionOptions />
				<FilterComponent />
				<GridToolbarQuickFilter
					sx={{
						flexBasis: "200px",
					}}
				/>
			</div>
		</GridToolbarContainer>
	);
};

const MoreOptions = () => {
	const { user } = useSelector((state) => state.auth);
	const { count } = useSelector((state) => state.candidates);
	if (count === 0) {
		return null;
	}
	return (
		<>
			<div className={"flex items-center flex-wrap gap-4 pt-2 mr-auto"}>
				<HideUnhideButton />
				{user?.role === "1096" && <BulkPromote />}
				<SendEmail onSendEmailModalClose={() => {}} />
				<BulkScheduler />
			</div>
		</>
	);
};

const CandidateSelectionOptions = () => {
	const { selectedRoundId } = useSelector((state) => state.filters);

	const {
		showHiddenCandidates,
		showCompletedInterviews: hasInterviewCompleted,
		showAllCandidates,
	} = useSelector((state) => state.candidates);
	const dispatch = useDispatch();
	return (
		<div className="flex flex-row items-center gap-4 pl-3 mr-auto flex-wrap">
			<CandidateFilterCheckbox
				checked={showAllCandidates}
				onChange={(e) => {
					dispatch(showAllCandidatesActionCreator());
				}}
				label={"Show All"}
				id="showAllCandidates"
				isVisible={!selectedRoundId}
				tooltip={"Shows all the unhidden candidates that are not promoted to any round"}
			/>
			<CandidateFilterCheckbox
				checked={hasInterviewCompleted}
				onChange={(e) => {
					dispatch(showCompletedInterviews());
				}}
				label={"Show Completed Interviews"}
				id="showCompletedInterviews"
			/>
			<CandidateFilterCheckbox
				checked={showHiddenCandidates}
				onChange={(e) => {
					if (!e.target.checked) {
						dispatch(hideHiddenCandidates());
					} else {
						dispatch(showHiddenCandidatesAction());
					}
				}}
				label={"Show Hidden Candidates"}
				id="showHiddenCandidates"
			/>
		</div>
	);
};

const CandidateFilterCheckbox = ({ checked, id, onChange, label, tooltip = "", isVisible = true }) => {
	return (
		<>
			<label
				title={tooltip}
				className={clsx(
					"align-middle flex items-center space-x-3 select-none",
					isVisible ? "visible" : "hidden",
				)}
			>
				<input checked={checked} type="checkbox" id={id} className="scale-110" onChange={onChange} />
				<span className="text-sm">{label}</span>
			</label>
		</>
	);
};
