import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useState } from "react";
import { useSelector } from "react-redux";
import InterviewScheduleComponent from "./InterviewScheduleComponent";
import { Button } from "@mui/material/node";
import { getCandidateIds } from "../../utilities/candidateUtils";
import { toast } from "react-toastify";

const BulkScheduler = () => {
	const [openScheduleModal, setOpenScheduleModal] = useState(false);
	const {
		candidates: { selectedCandidate, count, candidates },
	} = useSelector((state) => state);

	const toggleScheduleModal = () => {
		const length = getCandidateIds(candidates, selectedCandidate)?.length || 0;
		if (!length) {
			toast.warn("Candidate not found, or you have selected a completed interview candidate");
			return;
		}
		setOpenScheduleModal(true);
	};

	const closeScheduleModal = () => {
		setOpenScheduleModal(false);
	};

	return (
		<>
			<Button
				className="bg-orange-500 hover:bg-orange-700 border-none normal-case  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-between cursor-pointer disabled:opacity-50"
				disabled={count === 0}
				onClick={toggleScheduleModal}
			>
				<CalendarMonthIcon className="mr-1 h-5 w-4" />
				Schedule Interview ({count})
			</Button>
			{openScheduleModal ? (
				<InterviewScheduleComponent
					open={openScheduleModal}
					scheduleType={2}
					onClose={closeScheduleModal}
				/>
			) : (
				""
			)}
		</>
	);
};
export default BulkScheduler;
