import React, { useMemo, useState } from "react";
import { Menu, MenuItem, Select } from "@mui/material";
import { updateRoundDataForCandidate } from "../../../utilities/interviewReportsApi";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { updateCandidate } from "../../../app/actions/candidates/candidate";
import { useDispatch, useSelector } from "react-redux";
import { MenuItemStyles, SelectorStyles } from "../../../utilities/constants";
import clsx from "clsx";
import { apiSlice } from "../../../features/api/apiSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const RoundPromoteDemote = ({ params, rounds = [] }) => {
	const [isSaving, setIsSaving] = useState(false);
	const [roundId, setRoundId] = useState(null);
	const { openingId } = useParams();
	const dispatch = useDispatch();
	const { selectedRoundId } = useSelector((state) => state.filters);
	const handlePromoteDemote = async () => {
		try {
			setIsSaving(true);
			const { updatedReport, message } = await updateRoundDataForCandidate(params.id, {
				openingId,
				promoteToNextRound: !roundId,
				roundId,
			});
			setRoundId(null);
			dispatch(updateCandidate(updatedReport));
			dispatch(apiSlice.util.invalidateTags(["Opening-Rounds"]));
			setIsSaving(false);
			toast.success(message);
		} catch (error) {
			setIsSaving(false);
			toast.warn(error.message);
		}
	};
	const filteredRounds = useMemo(() => {
		return rounds?.filter((round) => {
			return round.roundId !== selectedRoundId;
		});
	}, [selectedRoundId, rounds]);
	return (
		<div className="h-full grid place-content-center w-full ">
			<div className="flex items-stretch gap-[1px]  ">
				<button
					type="button"
					onClick={handlePromoteDemote}
					disabled={rounds?.length === 0 || isSaving}
					className="flex-1 text-xs  text-ellipsis whitespace-nowrap overflow-hidden  cursor-pointer bg-blue-100 outline-none border-none hover:bg-blue-200 focus-within:bg-blue-200 pl-3 py-1 pr-1 rounded-l-3xl"
					title={
						rounds?.length === 0
							? "There are no rounds linked to the current opening"
							: "By defaults promotes the candidate to the next round in sequence. To promote to a different round, use the options icon on the right"
					}
				>
					Promote to{" "}
					{roundId
						? `${rounds.find((round) => round.roundId === roundId)?.roundData?.name}`
						: "next round"}
				</button>
				<MoveToARoundTab
					id={params.id}
					rounds={filteredRounds}
					roundId={roundId}
					setRoundId={setRoundId}
					isSaving={isSaving}
					currentRoundId={params.row?.roundId}
				/>
			</div>
		</div>
	);
};

export default RoundPromoteDemote;

const MoveToARoundTab = ({ id, rounds, roundId, setRoundId, isSaving, currentRoundId }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOpen = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleSelectRoundAndPromote = (e, roundId) => {
		setRoundId(roundId);
		handleClose();
	};
	const open = !!anchorEl;
	return (
		<>
			<button
				onClick={handleOpen}
				disabled={rounds.length === 0 || isSaving}
				type="button"
				className=" bg-blue-100 text-xs outline-none border-none hover:bg-blue-200 focus-within:bg-blue-200 pr-1 cursor-pointer  py-1  flex justify-center items-center rounded-r-3xl"
			>
				<ArrowDropDownIcon />
			</button>
			<div className="flex">
				<Menu
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
				>
					<MenuItem disabled={true}>-- Select A Round --</MenuItem>
					{rounds?.map((round) => {
						const {
							_id,
							roundId: itemRoundId,
							roundData: { name },
						} = round;
						const disabled = currentRoundId === itemRoundId;
						return (
							<MenuItem
								onClick={(e) => handleSelectRoundAndPromote(e, itemRoundId)}
								key={_id}
								disabled={disabled}
								className={clsx(
									"border-none outline-none text-sm",
									roundId === itemRoundId ? "bg-blue-50" : "",
								)}
							>
								{name}
							</MenuItem>
						);
					})}
					{roundId ? (
						<MenuItem
							className="border-none outline-none text-sm"
							onClick={(e) => {
								handleSelectRoundAndPromote(e, null);
							}}
						>
							Clear Selection
						</MenuItem>
					) : null}
				</Menu>
			</div>
		</>
	);
};

export const RoundSelector = ({ roundId, handleChange, id, rounds, selectedRoundId }) => {
	return (
		<Select
			labelId={`${id}__label`}
			id={`${id}__select_round`}
			value={roundId}
			onChange={handleChange}
			sx={SelectorStyles}
			MenuProps={MenuItemStyles}
			className="rounded-md text-sm "
		>
			<MenuItem value={"x"} selected={true} disabled={true}>
				-- Select A Round --
			</MenuItem>
			{rounds?.map((round) => {
				const {
					_id,
					roundId,
					openingId,
					roundData: { name },
				} = round;
				return (
					<MenuItem
						key={_id}
						value={roundId}
						className="border-none outline-none text-sm"
						disabled={roundId === selectedRoundId}
					>
						{name}
					</MenuItem>
				);
			})}
		</Select>
	);
};
