import React, { useRef, useState } from "react";
import { useGetRoundsForAnOpeningQuery, useReorderOpeningRoundsMutation } from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import { default as Button } from "./../QuestionBank/Button";
import { toast } from "react-toastify";
import clsx from "clsx";

const SortRounds = ({ handleModalClose }) => {
	return (
		<React.Fragment>
			<h3 className="text-center my-2 select-none">
				Re order the rounds linked to the current opening
			</h3>
			<div className="flex flex-col gap-4">
				<Rounds handleModalClose={handleModalClose} />
			</div>
		</React.Fragment>
	);
};

export default SortRounds;

const Rounds = ({ handleModalClose }) => {
	const roundsContainerRef = useRef(null);
	const { openingId } = useParams();
	const [saveNewOrderOfRounds, { isLoading }] = useReorderOpeningRoundsMutation();

	const { data } = useGetRoundsForAnOpeningQuery(openingId);
	const [rounds, setRounds] = useState(data?.rounds ?? []);

	const handleOrderSave = async () => {
		try {
			const res = await saveNewOrderOfRounds({ openingId, newItems: rounds }).unwrap();
		} catch (error) {
			console.log(error);
			const message = error?.data?.message || error.message;
			toast.warn(message);
		} finally {
			handleModalClose();
		}
	};

	return (
		<>
			<div id="nation" className="flex flex-col gap-2 rounds-container" ref={roundsContainerRef}>
				{rounds?.map((round) => {
					const { _id } = round;
					return <IndividualRound key={_id} round={round} setRounds={setRounds} rounds={rounds} />;
				})}
			</div>
			<Button className="self-end" disabled={isLoading} onClick={handleOrderSave}>
				Save{" "}
			</Button>
		</>
	);
};

const IndividualRound = ({ round, setRounds, rounds }) => {
	const {
		_id,
		roundId,
		roundData: { name, isFinalRound },
		sequenceNumber,
	} = round;
	const [isCurrentItemDroppable, setIsCurrentItemDroppable] = useState(false);

	function onDragStart(e) {
		e.dataTransfer.setData("text/round-id", e.target.id);
	}
	function onDrop(e) {
		e.preventDefault();
		const toShiftId = e.dataTransfer.getData("text/round-id");
		const toReplaceId = e.target.id;
		if (!toShiftId || !toReplaceId) {
			return;
		}
		const currentRounds = rounds;
		const indexOfToShift = currentRounds.findIndex((round) => round.roundId === toShiftId);
		const indexOfToReplace = currentRounds.findIndex((round) => round.roundId === toReplaceId);
		if (indexOfToShift === -1 || indexOfToReplace === -1) {
			return;
		}
		if (indexOfToShift < indexOfToReplace) {
			const leftShiftedItems = currentRounds
				.slice(indexOfToShift + 1, indexOfToReplace + 1)
				.map((round) => ({ ...round, sequenceNumber: round.sequenceNumber - 1 }));
			const shiftedItem = {
				...currentRounds[indexOfToShift],
				sequenceNumber: currentRounds[indexOfToReplace].sequenceNumber,
			};
			const newItems = [
				...currentRounds.slice(0, indexOfToShift),
				...leftShiftedItems,
				shiftedItem,
				...currentRounds.slice(indexOfToReplace + 1),
			];

			setRounds(newItems);
		} else if (indexOfToShift > indexOfToReplace) {
			const rightShiftItems = currentRounds.slice(indexOfToReplace, indexOfToShift).map((round) => ({
				...round,
				sequenceNumber: round.sequenceNumber + 1,
			}));
			const shiftedItem = {
				...currentRounds[indexOfToShift],
				sequenceNumber: currentRounds[indexOfToReplace].sequenceNumber,
			};
			const newItems = [
				...currentRounds.slice(0, indexOfToReplace),
				shiftedItem,
				...rightShiftItems,
				...currentRounds.slice(indexOfToShift + 1),
			];
			setRounds(newItems);
		}
		setIsCurrentItemDroppable(false);
	}
	function onDragEnter(e) {
		const targetContainerId = e.target.id;
		if (targetContainerId && rounds?.some((round) => round.roundId === targetContainerId)) {
			setIsCurrentItemDroppable(true);
		}
	}
	function onDragOver(e) {
		if (e.dataTransfer.types.includes("text/round-id")) {
			e.preventDefault();
		}
	}

	function onDragLeave(e) {
		setIsCurrentItemDroppable(false);
	}

	return (
		<div
			draggable={"true"}
			className={clsx(
				"cursor-move select-none border-[1px] border-slate-500 p-2 drop-target",
				isCurrentItemDroppable ? "border-dashed " : "border-solid",
			)}
			id={roundId}
			onDrop={onDrop}
			onDragEnter={onDragEnter}
			onDragOver={onDragOver}
			onDragStart={onDragStart}
			onDragLeave={onDragLeave}
		>
			{name}
		</div>
	);
};
