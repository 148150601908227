import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { executeCustomEvent, getCustomEventsAndActions } from "../utilities/eventsApi";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { renderConfirmationDialog } from "./confirmation/ConfirmationDialog";
import { CircularProgress } from "@mui/material";

export default function ActionsMenu() {
	const [customEvents, setCustomEvents] = React.useState([]);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const open = Boolean(anchorEl);

	const {
		candidates: { selectedCandidate, count },
		openingDetails: {
			selectedOpening: { _id: openingId },
		},
	} = useSelector((state) => state);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (_, el) => {
		setAnchorEl(null);
		if (typeof el === "object" && el._id) {
			// if (count == 0) {
			// 	toast.error("Select at least one candidate to execute event");
			// 	return;
			// }
			renderConfirmationDialog(
				count > 0
					? "Are you sure you want to execute this event?"
					: "Are you sure you want to execute this event without selecting any candidate? Any variables being used will not be replaced with candidate data.",
				() => {},
				() => executeEvent(el._id),
				true,
				count > 0 ? 0 : 5000,
			);
		}
	};

	const executeEvent = async (eventId) => {
		try {
			let response = await executeCustomEvent({
				openingId,
				event: eventId,
				reports: selectedCandidate.join(","),
			});
			if (response && response.data) {
				toast.success("Event executed successfully");
			} else {
				toast.error("Error executing event");
			}
		} catch (error) {
			console.log(error);
			// toast.error("Error executing event");
		}
	};

	const getAndSetCustomEvents = async () => {
		setLoading(true);
		try {
			let response = await getCustomEventsAndActions({ openingId });
			if (response && response.data) {
				let events = response.data.events?.map((el) => {
					return el;
				});
				setCustomEvents([...events]);
			} else {
				console.log(response);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	React.useEffect(() => {
		if (open) {
			getAndSetCustomEvents();
		}
	}, [open]);
	const KeyBoardIcon = () => {
		return open ? (
			<KeyboardArrowUp className="mr-1 h-5 w-5" />
		) : (
			<KeyboardArrowDown className="mr-1 h-5 w-5" />
		);
	};
	return (
		<div>
			<Button
				id="basic-button"
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					backgroundColor: "#3B82F6",
					":hover": {
						backgroundColor: "#1D4ED8",
					},
				}}
				className="normal-case w-full  focus:outline-none focus:border-none px-4 py-2 transition duration-300 ease-in-out text-white rounded-lg flex items-center justify-start gap-1"
			>
				<KeyBoardIcon />
				Actions
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
			>
				{loading ? (
					<MenuItem
						disabled
						className="flex justify-center align-middle"
						style={{
							minWidth: "7rem",
						}}
					>
						<CircularProgress size={24} />
					</MenuItem>
				) : customEvents.length == 0 ? (
					<MenuItem onClick={handleClose}>No Custom Events Found</MenuItem>
				) : (
					customEvents.map((el, index) => {
						return (
							<MenuItem
								key={index}
								className="hover:scale-105 transition-all hover:bg-sky-50"
								onClick={(e) => handleClose(e, el)}
							>
								{el.eventLabel}
							</MenuItem>
						);
					})
				)}
			</Menu>
		</div>
	);
}
