import { Box, Modal } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { customFieldsModalStyles } from "../../utilities/constants";
import { RoundSelector } from "../grid/columnRenderer/RoundPromoteDemote";
import { apiSlice, useGetRoundsForAnOpeningQuery } from "../../features/api/apiSlice";
import { useParams } from "react-router-dom";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { default as MyButton } from "../QuestionBank/Button";
import { bulkUpdateRoundDataForCandidates } from "../../utilities/interviewReportsApi";
import { toast } from "react-toastify";
import { bulkUpdateCandidateRounds, unselectAllCandidate } from "../../app/actions/candidates/candidate";
const BulkPromote = () => {
	const { selectedCandidate, count } = useSelector((state) => state.candidates);
	const { selectedRoundId } = useSelector((state) => state.filters);
	const { openingId } = useParams();
	const dispatch = useDispatch();
	const [isSaving, setIsSaving] = useState(false);
	const [open, setOpen] = useState(false);
	const [roundId, setRoundId] = useState("x");
	const { data } = useGetRoundsForAnOpeningQuery(openingId);

	const handleModalOpen = () => {
		setOpen(true);
	};

	const handleModalClose = () => {
		// isSaving will read the latest value for the current render, because we know that, each function is created for each render
		// that means, when setIsSaving
		if (!isSaving) {
			// false
			setOpen(false);
			setRoundId("x");
		}
	};

	const handleRoundChange = (e) => {
		setRoundId(e.target.value);
	};

	const handleBulkPromote = async () => {
		try {
			setIsSaving(true);
			const res = await bulkUpdateRoundDataForCandidates(selectedCandidate, roundId);
			// isSaving
			dispatch(unselectAllCandidate());
			dispatch(
				bulkUpdateCandidateRounds({
					roundId,
					ids: selectedCandidate,
				}),
			);
			dispatch(apiSlice.util.invalidateTags(["Opening-Rounds"]));
			toast.success("Bulk promote success!");
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setIsSaving(false);
			console.log("IsSaving value from the finally block", isSaving);
			handleModalClose();
		}
	};

	return (
		<div>
			<Button
				variant="contained"
				startIcon={<SyncAltIcon className="scale-75" />}
				className={`bg-lime-500 hover:bg-lime-600 shadow-none normal-case focus:outline-none focus:border-none transition duration-300 ease-in-out text-white px-4 py-2 rounded-md flex items-center justify-between disabled:opacity-50`}
				onClick={handleModalOpen}
				disabled={data?.rounds?.length === 0 || count === 0}
				title={data?.rounds?.length === 0 ? "There are no rounds linked to the current opening" : ""}
			>
				Bulk Promote ({count})
			</Button>
			<Modal open={open} onClose={handleModalClose}>
				<Box sx={customFieldsModalStyles}>
					<h3 className="mb-5">
						Please select a round to promote or demote the selected candidates
					</h3>
					<RoundSelector
						roundId={roundId}
						id={"bulk-promote"}
						handleChange={handleRoundChange}
						rounds={data?.rounds ?? []}
						selectedRoundId={selectedRoundId}
					/>
					<div className="flex flex-row justify-end gap-4 items-center">
						<MyButton
							colorVariant={"red"}
							type="button"
							onClick={handleModalClose}
							disabled={isSaving}
						>
							Cancel
						</MyButton>
						<MyButton
							type="submit"
							onClick={handleBulkPromote}
							disabled={isSaving || roundId === "x"}
						>
							Move
						</MyButton>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default BulkPromote;
