import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideCandidates, unhideCandidates } from "../../app/actions/candidates/candidate";
import { toast } from "react-toastify";
import clsx from "clsx";

const HideUnhideButton = () => {
	const dispatch = useDispatch();
	const { count, showHiddenCandidates } = useSelector((state) => state.candidates);
	const hideUnhideReports = () => {
		if (count > 0) {
			dispatch(!showHiddenCandidates ? hideCandidates() : unhideCandidates());
		} else {
			toast.warn("Please select some candidates first.");
		}
	};
	return (
		<Tooltip
			title={<>{showHiddenCandidates ? "Unhide selected candidates" : "Hide selected candidates"}</>}
		>
			<Button
				variant="contained"
				className={`bg-sky-500 hover:bg-sky-600 shadow-none normal-case focus:outline-none focus:border-none transition duration-300 ease-in-out text-white px-4 py-2 rounded-md flex items-center justify-between disabled:opacity-50`}
				disabled={count === 0}
				onClick={hideUnhideReports}
				startIcon={
					showHiddenCandidates ? (
						<Visibility className="scale-75" />
					) : (
						<VisibilityOff className="scale-75" />
					)
				}
			>
				{!showHiddenCandidates ? "Hide" : "Unhide"} ({count})
			</Button>
		</Tooltip>
	);
};

export default HideUnhideButton;
