import { ADD_FILTER, ADD_GRID_FILTER, ADD_ROUND_ID, REMOVE_ALL_FILTER, REMOVE_FILTER } from "./types";

export const addFilterAction = (filter) => (dispatch) => {
	dispatch({
		type: ADD_FILTER,
		payload: filter,
	});
};

export const removeFilterAction =
	(filter = []) =>
	(dispatch) => {
		dispatch({
			type: REMOVE_FILTER,
			payload: filter,
		});
	};

export const onGridFilterModalChange = (filter) => (dispatch) => {
	dispatch({
		type: ADD_GRID_FILTER,
		payload: filter,
	});
};

export const removeAllFilters = () => (dispatch) => {
	dispatch({
		type: REMOVE_ALL_FILTER,
		payload: [],
	});
};
export const addRoundId = (roundId) => (dispatch) => {
	dispatch({
		type: ADD_ROUND_ID,
		payload: { roundId: roundId },
	});
};
